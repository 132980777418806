@import './bundle.scss';

.bmw-theme{
    display: flex;
    flex-direction: column;
    min-height: -webkit-fill-available;
    & .main-container{
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }
    & .action-buttons{
        display: flex;
        border-top: 1px solid var(--input-border-gray);
        padding: 20px 0;
        justify-content: flex-end;
    }
    & .fs-button{
        &-btn{
            background-color: var(--primary-blue) !important;
            border: 1px solid var(--primary-blue)!important;
            color : var(--white) !important;
            font-family: $helvetica !important;
            border-radius: 0;
            font-size: 11px;
            line-height: 11px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 15px 30px;
            opacity: 1;
            cursor: pointer;
            &:hover, &:focus{
                background-color: var(--white) !important;
                border: 1px solid var(--primary-blue)!important;
                color : var(--primary-blue) !important;
            }
            &-disabled{
                cursor: not-allowed;
                opacity: .5;
                &:hover, &:focus{
                    background-color: var(--primary-blue) !important;
                    border: 1px solid var(--primary-blue)!important;
                    color : var(--white) !important;
                }
            }
            &-secondary{
                background-color: var(--white) !important;
                border: 1px solid var(--border-gray-dark) !important;
                color: var(--dark) !important;
                padding: 15px 30px !important;

                &:hover, &:focus{
                    border: 1px solid var(--black) !important;
                    color: var(--dark) !important;
                }
            }
        }
    } 
    & .mat-mdc-checkbox label{
        font-family: $helvetica !important;
        font-size: 12px !important;
    }
    
    & .fs-anchor{
        font-family: $helvetica !important;
    }  
    & .faq{
        & .title{
            padding-bottom: 20px;
            margin-bottom: 30px!important;
            border-bottom: 1px solid var(--input-border-gray);
            font-family: $helvetica;
            font-size: 18px;
            line-height: 21px;
            margin-top: 0;
        }
        &-container{
            padding: 0 20px;
            width: auto !important;
            font-family: $helvetica;            
            & .fs-nav-mobile {
                margin-bottom: 20px;
                & .mat-select-arrow:after{
                    font-size: 13px;
                }
            }
        }
        &-content{
            & .expansionPanel .mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title{
                font-size: 16px;
                line-height: 21px;
                font-weight: 700;
                font-family: $helvetica;
                @media (max-width: $medium){
                    font-size: 12px;
                }
            }
            & .expansionPanel .mat-expansion-panel .mat-expansion-indicator:after{
                font-size: 15px;
            }
            & .expansionPanel .mat-expansion-panel .mat-expansion-panel-header-title{
                font-size: 12px;
                font-weight: 400;
                border-bottom: none;
                height: auto;
                margin: 0;
                display: block;
                font-family: $helvetica;
            }
            & .mat-expansion-panel-content .mat-expansion-panel-body{
                font-family: $helvetica;
                font-size: 12px;
                @media (max-width: $medium){
                    font-size: 10px;
                }
            }
        }
    }
    
    & .fs-text{
        font-family: $helvetica !important;        
        &.paragraph {           
            font-size: 16px;
            color: var(--dark);
        }
        &.error{
            font-style: normal;
            font-size: 10px !important;
            margin: 5px 0 0;
            line-height: 12px;
            font-family: $helvetica;
        }
    }
    & .fs-print-page {
        & .print-page-header{
            & svg{
                fill: var(--primary-blue);
            }
        }
    }
    & .fs-input{
        margin-bottom: 0;
        & span{
            position: absolute; 
            left: -999em;
        }
        & .padding-fix{
            height: 40px;
            padding: .4375em !important;
        }
        & .input-group{
            border: 1px solid var(--input-border-gray);
            padding: .4375em;
            display: flex;
            justify-content: flex-end;
            background: transparent;
            height: auto;
            border-bottom: 1px solid var(--valid-green);
            &:focus-within{
                border-bottom: 1px solid var(--valid-green);
            }
            &.error-border{
                border-bottom: 1px solid var(--error-red);
            }
        }
        & input{
            font-family: $helvetica;
            height: 40px;
        }
        & .fs-input-error{
            font-family: $helvetica;
            font-size: 11px;
            margin: 3px 0 0;
            color: var(--error-red);
        }
        ::placeholder {
            font-family: $helvetica;
        }
    }
    & .material-icons {
        font-family: 'MaterialIconLibrary', sans-serif;
    }
    
    & .fs-breadcrumb{
        font-family: $helvetica;
        & .back{
            cursor: pointer;
        }   
    }
}

* {
    -webkit-font-smoothing: antialiased;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    box-sizing: border-box
  }
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
