:root {
    --mini-menu-bg: #f2f4f4;
    --mini-menu-border: #e2e2e2;
    --menu-border-color: #D8D8D8;
    --light-gray: #EDEDEE;
    --black: 0, 0, 0;
    --dark-gray: #414141;
    --medium-gray-06: rgba(0, 0, 0, 0.6);
    --primary-blue: #0067DB;    
    --blue: #1c69d3;
    --white:#FFFFFF;
    --input-border-gray: #CACACA;
    --border-gray: #dedede;
    --dark:#221F1F;
    --border-gray-dark: #979797;
    --navbar-gray: #404040;
    --header-gray: #F6F6F6;
    --section-border: #DEDEDE;
    --title-border: #221f1f;
    --mini-background: #f1f1f1;

    --profile-gray: #F6F6F6;
    --header-font-color: var(--dark);
    --container-background: #FFFFFF;
    --shadow-color: 0, 0, 0;
    --error-red: #FF1744;    
    --valid-green: #43a047;
    --border-blue:#3f51b5;
    --myprofile-background: #f1f1f1;
    --spinner-background: rgba(255,255,255,0.2);

    --mini-red: #ac2430;
    --profile-light-gray: #f9f9f9;
    --faq-link-hover: #0f80ff;

    --footer-text: #77787b;
    --header-dropdown-border: #dadada;
    
    --opacity0: 0;
    --opacity002: 0.02;
    --opacity01: 0.1;
    --opacity02: 0.2;
    --opacity04: 0.4;
    --opacity032: 0.32;
    --opacity06: 0.6;
    --opacity065: 0.65;
    --opacity004: 0.04;

    --primary-blue: #0067DB;
    --black: #000000;
    --checkbox-white:#fafafa;
}
// Fonts
$helvetica: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$helvetica-light: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$SegoeUI: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$login-bg-tablet: "../../shared/assets/images/login-bg-tablet.jpg";
$login-bg-mini-tablet: "../../shared/assets/images/login-bg-mobile-canada-mini.png";
$bmw-background-image: "../../shared/assets/images/bmw-background-image.jpg";
$mini-background-image: "../../shared/assets/images/login-bg-canada-mini.png";
$mini-background-image-mobile: "../../../../my-profile-mini-us/src/assets/images/login-bg-mobile-mini.jpg";
$mini-serif-regular: 'mini-serif-regular', serif;

// Fonts-size
$font-size-13: 13px;
$font-size-14: 14px;

//MINI Fonts
$mini-sans-serif-regular: 'mini-sans-serif-regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Breakpoints
$medium: 760px;
$xmedium: 600px;
$small: 400px;
$xsmall: 365px;
$large: 900px;
$xlarge: 1140px;

//Navbar
$navbar-max-width: 1100px;
$desktop-container-width: 1200px;

$mobile: 425px;
$tablet: 768px;
$desktop: 1366px;

$minimobile-se: 375px;
$minimobile: 580px;
$minixmedium: 670px;
$minismall: 520px;

$caxlarge: 1024px;
$screen-medium:767px;

//images
$errorheaderimage: '../../shared/assets/images/building-header.jpg';
